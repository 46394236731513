import { lazy, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Styles } from "../styles/styles";
import routes from "./config";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <MaybeHeader />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      <MaybeFooter />
    </Suspense>
  );
};
const HEADERLESS_PAGES = ['/reset-password', '/confirm-email'];

const isHeaderless = (path: string) => {
  for (var i in HEADERLESS_PAGES) {
    if (path.toLowerCase().startsWith(HEADERLESS_PAGES[i])) {
      return true;
    }
  }
  return false;
}

const MaybeFooter = () => {
  if (!isHeaderless(useLocation().pathname)) {
    return <Footer />
  }
  return <div></div>
}



const MaybeHeader = () => {
  if (!isHeaderless(useLocation().pathname)) {
    return <Header />
  }
  return <div></div>
}

export default Router;
