const routes = [
  {
    path: ['/', '/home'],
    exact: true,
    component: 'Home',
  },
  {
    path: ['/payment'],
    exact: true,
    component: 'Payment',
  },
  {
    path: ['/reset-password'],
    exact: true,
    component: 'ResetPassword',
  },
  {
    path: ['/confirm-email'],
    exact: true,
    component: 'ConfirmEmail',
  },
]

export default routes
